import { default as round_451_45my_45quotes2WjXuTUkgNMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-my-quotes.vue?macro=true";
import { default as round_451_45outcomeAuGQOLL2roMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-outcome.vue?macro=true";
import { default as round_452_45my_45quotesu4VImjCfy7Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-my-quotes.vue?macro=true";
import { default as round_452_45outcomekSrhTKZzGNMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-outcome.vue?macro=true";
import { default as summary5F2Ynvhk1PMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/summary.vue?macro=true";
import { default as _91_46_46_46slug_93kgKwLE3c9MMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue?macro=true";
import { default as active_45rfqs3cLP9ErZ4VMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/active-rfqs.vue?macro=true";
import { default as closed_45rfqsGdIj4hXvoaMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/closed-rfqs.vue?macro=true";
import { default as indexn8DQShM4e9Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue?macro=true";
import { default as indexgsp2ClLYNJMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue?macro=true";
import { default as depthficjfgXivdMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/depth.vue?macro=true";
import { default as indexpEkMLGimKlMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/index.vue?macro=true";
import { default as spreadx6BilMQF9MMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/spread.vue?macro=true";
import { default as volumeGhVhg0wauXMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/volume.vue?macro=true";
import { default as indexkUPgG5CN5eMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/pages/login/index.vue?macro=true";
import { default as indexI2I9IQ70YpMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/verify/index.vue?macro=true";
import { default as indexY3SHM1WiHoMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue?macro=true";
import { default as mobile_45gatePs3ZIurrZqMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue?macro=true";
import { default as monitor_45active_45projects0rqdjFULZhMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/monitor-active-projects/pages/monitor-active-projects.vue?macro=true";
import { default as indexxKloAqOzUDMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue?macro=true";
import { default as indexl0UR4nUuzcMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue?macro=true";
import { default as authorizationtm75EXUtKkMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/pages/v2/authorization.vue?macro=true";
import { default as indexP3TFTIKcavMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue?macro=true";
import { default as component_45stubKEu5zCYCW6Meta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKEu5zCYCW6 } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "rfqId-round-1-my-quotes",
    path: "/:rfqId()/round-1-my-quotes",
    meta: round_451_45my_45quotes2WjXuTUkgNMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-my-quotes.vue")
  },
  {
    name: "rfqId-round-1-outcome",
    path: "/:rfqId()/round-1-outcome",
    meta: round_451_45outcomeAuGQOLL2roMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-outcome.vue")
  },
  {
    name: "rfqId-round-2-my-quotes",
    path: "/:rfqId()/round-2-my-quotes",
    meta: round_452_45my_45quotesu4VImjCfy7Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-my-quotes.vue")
  },
  {
    name: "rfqId-round-2-outcome",
    path: "/:rfqId()/round-2-outcome",
    meta: round_452_45outcomekSrhTKZzGNMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-outcome.vue")
  },
  {
    name: "rfqId-summary",
    path: "/:rfqId()/summary",
    meta: summary5F2Ynvhk1PMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/summary.vue")
  },
  {
    name: "academy-slug",
    path: "/academy/:slug(.*)*",
    meta: _91_46_46_46slug_93kgKwLE3c9MMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue")
  },
  {
    name: "active-rfqs",
    path: "/active-rfqs",
    meta: active_45rfqs3cLP9ErZ4VMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/active-rfqs.vue")
  },
  {
    name: "closed-rfqs",
    path: "/closed-rfqs",
    meta: closed_45rfqsGdIj4hXvoaMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/closed-rfqs.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: indexn8DQShM4e9Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexgsp2ClLYNJMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue")
  },
  {
    name: "leaderboards-depth",
    path: "/leaderboards/depth",
    meta: depthficjfgXivdMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/depth.vue")
  },
  {
    name: "leaderboards",
    path: "/leaderboards",
    meta: indexpEkMLGimKlMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/index.vue")
  },
  {
    name: "leaderboards-spread",
    path: "/leaderboards/spread",
    meta: spreadx6BilMQF9MMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/spread.vue")
  },
  {
    name: "leaderboards-volume",
    path: "/leaderboards/volume",
    meta: volumeGhVhg0wauXMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/volume.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexkUPgG5CN5eMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/pages/login/index.vue")
  },
  {
    name: "login-verify",
    path: "/login/verify",
    meta: indexI2I9IQ70YpMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/login/verify/index.vue")
  },
  {
    name: "magic-link",
    path: "/magic-link",
    meta: indexY3SHM1WiHoMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue")
  },
  {
    name: "mobile-gate",
    path: "/mobile-gate",
    meta: mobile_45gatePs3ZIurrZqMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue")
  },
  {
    name: "monitor-active-projects",
    path: "/monitor-active-projects",
    meta: monitor_45active_45projects0rqdjFULZhMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/monitor-active-projects/pages/monitor-active-projects.vue")
  },
  {
    name: "password-new",
    path: "/password/new",
    meta: indexxKloAqOzUDMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexl0UR4nUuzcMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue")
  },
  {
    name: "v2-authorization",
    path: "/v2/authorization",
    meta: authorizationtm75EXUtKkMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/pages/v2/authorization.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: indexP3TFTIKcavMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue")
  },
  {
    name: component_45stubKEu5zCYCW6Meta?.name,
    path: "/privacy-policy",
    component: component_45stubKEu5zCYCW6
  },
  {
    name: component_45stubKEu5zCYCW6Meta?.name,
    path: "/terms-of-use",
    component: component_45stubKEu5zCYCW6
  },
  {
    name: component_45stubKEu5zCYCW6Meta?.name,
    path: "/playbook",
    component: component_45stubKEu5zCYCW6
  },
  {
    name: component_45stubKEu5zCYCW6Meta?.name,
    path: "/",
    component: component_45stubKEu5zCYCW6
  }
]