import revive_payload_client_1RXbDs5ciD from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_M4sZfmamEJ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9LGxOPttxy from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_JsQxZwWmx9 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_j2j4jz1FDk from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0YKQKmDqDb from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_oCHb4oHczF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Agi5x9LuBD from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.7.2_vue@3.5.12_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/liquidity.forgd.com/.nuxt/components.plugin.mjs";
import prefetch_client_8wtDxZ6m5a from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.0__ioredis@5.4_o3atejc6knonhorsgficshx3x4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_SzMQrTD6n3 from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_CEqUrqXETr from "/opt/render/project/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5_he4mewzjykqj6zhk5rddbrjiga/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_4BWOcB0t6M from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_5lc3rpknthmu3hrhiq5k4ry5zi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_MKpdiooqgy from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_5lc3rpknthmu3hrhiq5k4ry5zi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_tk2vdI2OQN from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_5lc3rpknthmu3hrhiq5k4ry5zi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_62M0TI8w5Y from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vRpwHHLxLv from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.10.1_sass-embedded@1_zdzyu5ovugzembq3nakw2h2uty/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_uQ0MjOGoYG from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._dxv3vx5z3w47i6jokglxislsdi/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_Mc7hO8qBuY from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._dxv3vx5z3w47i6jokglxislsdi/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_L3PBzbTrWm from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._dxv3vx5z3w47i6jokglxislsdi/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import search_aUu6HJpKV6 from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/plugins/search.ts";
import lottie_AzpYEGWRZG from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/lottie.ts";
import sentry_client_3lKG8ZOviM from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/sentry.client.ts";
import tracing_client_csePbkLi2Z from "/opt/render/project/src/libs/nuxt-forgd-layer-core/plugins/tracing.client.ts";
export default [
  revive_payload_client_1RXbDs5ciD,
  unhead_M4sZfmamEJ,
  router_9LGxOPttxy,
  supabase_client_0Xg0t7ySbj,
  payload_client_JsQxZwWmx9,
  navigation_repaint_client_j2j4jz1FDk,
  check_outdated_build_client_0YKQKmDqDb,
  chunk_reload_client_oCHb4oHczF,
  plugin_vue3_Agi5x9LuBD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8wtDxZ6m5a,
  preview_client_SzMQrTD6n3,
  plugin_CEqUrqXETr,
  slideovers_4BWOcB0t6M,
  modals_MKpdiooqgy,
  colors_tk2vdI2OQN,
  plugin_client_62M0TI8w5Y,
  plugin_vRpwHHLxLv,
  scrollbars_client_uQ0MjOGoYG,
  presets_Mc7hO8qBuY,
  variables_L3PBzbTrWm,
  search_aUu6HJpKV6,
  lottie_AzpYEGWRZG,
  sentry_client_3lKG8ZOviM,
  tracing_client_csePbkLi2Z
]